/* @define Quote */

.Quote {
  color: var(--color-typo-Gold-200);
}

.Quote-footer {
  text-transform: uppercase;
}

@media (max-width: 48rem) {
  .Quote-text {
    font: var(--typo-quote-18);
  }

  .Quote-footer {
    font: var(--typo-quote-14);
    margin-top: var(--spacing-16);
  }
}

@media (min-width: 48.0625rem) {
  .Quote-footer {
    font: var(--typo-quote-16);
    margin-top: var(--spacing-24);
  }
}

@media (min-width: 48.0625rem) and (max-width: 64rem) {
  .Quote-text {
    font: var(--typo-quote-22);
  }
}

@media (min-width: 64.0625rem) {
  .Quote-text {
    font: var(--typo-quote-30);
  }
}
