/* @define Gallery */

:root {
  --Gallery-borderWidth: 0.1rem;
  --Gallery-expandedBgColor: rgba(0, 0, 0, 0.75);
  --Gallery-photoWrapperSpacing: var(--spacing-45);
  --Gallery-buttonPadding: calc(
    var(--Gallery-photoWrapperSpacing) - var(--spacing-24)
  );
  --Gallery-itemWidthDesktop: 25%;
  --Gallery-itemWidthMobile: 50%;
}

.Gallery-currentPhotoWrapper {
  margin: 0 var(--Gallery-photoWrapperSpacing);

  /* Keeps aspect ratio */
  padding: 0 0 56.25%;
  position: relative;
}

.Gallery-picture,
.Gallery-image {
  /* Spans entire height of parent */
  height: 100%;
  left: 0;
  object-fit: cover;
  object-position: center;
  position: absolute;
  top: 0;

  /* Spans entire width of parent */
  width: 100%;
}

.Gallery-selectors,
.Gallery-list {
  box-sizing: border-box;
  display: flex;

  /* Spans entire width of parent */
  width: 100%;
}

.Gallery-selectors {
  justify-content: space-between;
}

.Gallery-button {
  color: var(--color-typo-Gold-200);
  font-size: var(--spacing-24);
}

.Gallery-button,
.Gallery-imageButton {
  cursor: pointer;
  outline: none;
}

.Gallery-previous {
  padding-right: var(--Gallery-buttonPadding);
}

.Gallery-next {
  padding-left: var(--Gallery-buttonPadding);
}

.Gallery-list {
  align-items: center;
  margin-bottom: var(--spacing-16);
  overflow: hidden;
}

.Gallery-listItem {
  box-sizing: border-box;
  overflow: hidden;
  padding-right: var(--spacing-16);
  transform: translateX(0);
  transition: transform var(--transition-duration-500)
    var(--transition-default-timing-in);
}

.Gallery-imageButton {
  border: var(--Gallery-borderWidth) solid transparent;

  /* Keeps aspect ratio */
  padding-bottom: 56.25%;
  position: relative;

  /* Spans entire width of parent */
  width: 100%;
}

/* stylelint-disable-next-line */
[aria-current="true"] .Gallery-imageButton {
  border-color: var(--color-Gold-200);
}

@media (max-width: 48rem) {
  .Gallery-listItem {
    min-width: calc(var(--Gallery-itemWidthMobile) + var(--spacing-16) / 2);
  }
}

@media (min-width: 48.0625rem) {
  .Gallery-listItem {
    min-width: calc(var(--Gallery-itemWidthDesktop) + var(--spacing-16) / 4);
  }
}
