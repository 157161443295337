/* @define EventCalendar */

@import "./event/event.css";

:root {
  --EventCalendar-listBorderWidth: 1px;
}

.EventCalendar-event:not(:first-child) {
  margin-top: var(--spacing-10);
}

.EventCalendar-eventList + .EventCalendar-month {
  margin-top: var(--spacing-30);
}

.EventCalendar-eventList {
  border-top: var(--EventCalendar-listBorderWidth) solid
    var(--color-page-divider);
}

.EventCalendar-nav{
  display: flex;
  margin-bottom: var(--spacing-20);
}

/* stylelint-disable */
.EventCalendar-nav .DesktopLink {
  font: var(--typo-link-18);
  padding: var(--spacing-10);
  text-align: center;
  width: 240px;
}

.EventCalendar-nav .DesktopLink:first-child {
  margin-left: 0;
}

.EventCalendar-nav .DesktopLink .LinkIndicator {
  display: none;
}
/* stylelint-enable */

@media (max-width: 48rem) {
  .EventCalendar-month {
    font: var(--typo-link-14);
    padding-bottom: var(--spacing-10);
  }

  .EventCalendar-eventList {
    padding-top: var(--spacing-16);
  }

  /* stylelint-disable-next-line */
  .EventCalendar-nav .DesktopLink {
    margin: 0 var(--spacing-10);
    width: 50%;
  }
}

@media (min-width: 48.0625rem) {
  .EventCalendar-month {
    font: var(--typo-link-16);
    padding-bottom: var(--spacing-20);
  }

  .EventCalendar-eventList {
    padding-top: var(--spacing-30);
  }
}
