/* @define TeaserPagination */

.TeaserPagination{
  align-items: center;
  display: flex;
  justify-content: center;
  margin: var(--spacing-20) auto;
  text-align: center;
}

.TeaserPagination-link[aria-current] {
  background-color: var(--color-Gold-200);
}

@media (max-width: 48rem) {
  /* stylelint-disable-next-line */
  .TeaserPagination .DesktopLink {
    height: auto;
    margin: 0 var(--spacing-10);
  }
}
