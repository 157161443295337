/** @define Icon; */

:root {
  --Icon-size: 1em;
}

.Icon {
  display: block;
  fill: currentColor;
  height: var(--Icon-size);
  width: var(--Icon-size);
}
