/* @define Button */

:root {
  --Button-iconSize: 0.875em;
}

.Button {
  align-items: center;
  background: var(--color-Gold-500)
    linear-gradient(to top, var(--color-Gold-200) 100%, transparent);
  background-position: bottom;
  background-repeat: repeat-x;

  /* set the height of the background hover color to 0, will be transitioned to 100% on hover/focus */
  background-size: 100% 0;

  /* Makes the border round */
  border-radius: 999px;
  color: var(--color-typo-White);
  cursor: pointer;
  display: flex;
  font: var(--typo-button-18);
  justify-content: center;
  outline: none;
  padding: var(--spacing-14) var(--spacing-30);
  text-decoration: none;
  text-transform: uppercase;
  transition: background-size var(--transition-default-duration-out)
    var(--transition-default-timing-out);
  width: max-content;
}

.Button:hover,
.Button:focus {
  /* animates the background cover from bottom to top */
  background-size: 100% 100%;
  transition-duration: var(--transition-default-duration-in);
  transition-timing-function: var(--transition-default-timing-in);
}

.Button-icon--right {
  flex-direction: row-reverse;
}

.Button-icon {
  display: block;
  font-size: var(--Button-iconSize);
}

.Button-icon--left .Button-icon {
  margin-right: var(--spacing-8);
}

.Button-icon--right .Button-icon {
  margin-left: var(--spacing-8);
}