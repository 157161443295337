@media (max-width: 48rem) {
  .DefaultPage-pattern + .DefaultPage-pattern,
  .TwoColumn-right > * + *,
  .TwoColumn-left > * + * {
    margin-top: var(--spacing-30);
  }
}

@media (min-width: 48.0625rem) {
  .DefaultPage-pattern + .DefaultPage-pattern,
  .TwoColumn-right > * + *,
  .TwoColumn-left > * + * {
    margin-top: var(--spacing-45);
  }
}
