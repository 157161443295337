/* @define Publication */

.Publication-title {
  line-height: 1;
}

.Publication-link {
  color: var(--color-typo-Gold-200);
  font-weight: bold;
}

.Publication-link:hover,
.Publication-link:focus {
  color: var(--color-typo-Gold-500);
}

.Publication-description {
  margin-top: var(--spacing-16);
}

.Publication-link {
  display: inline-block;
  font-weight: bold;
  margin-top: var(--spacing-4);
}

@media (max-width: 48rem) {
  .Publication-title {
    font: var(--typo-headline-18);
  }

  .Publication-description,
  .Publication-link {
    font: var(--typo-copy-14);
  }
}

@media (min-width: 48.0625rem) and (max-width: 120rem) {
  .Publication-title {
    font: var(--typo-headline-25);
  }

  .Publication-description,
  .Publication-link {
    font: var(--typo-copy-16);
  }
}

@media (min-width: 120.0625rem) {
  .Publication-title {
    font: var(--typo-headline-35);
  }

  .Publication-description,
  .Publication-link {
    font: var(--typo-copy-20);
  }
}
