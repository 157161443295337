/* @define DesktopLink; weak */

@import "./link-indicator/link-indicator.css";
@import "./sub-page-indicators/sub-page-indicators.css";

.DesktopLink {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  letter-spacing: 0;
  padding: 0 var(--spacing-10);
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
}

.DesktopLink:hover,
.DesktopLink:focus {
  transition-duration: var(--transition-default-duration-in);
  transition-timing-function: var(--transition-default-timing-in);
}

.DesktopLink:not(.DesktopLink--primary) {
  transition: color var(--transition-default-duration-out)
    var(--transition-default-timing-out);
}

.DesktopLink:not(.DesktopLink--primary):hover,
.DesktopLink:not(.DesktopLink--primary):focus,
.DesktopLink:not(.DesktopLink--primary)[aria-current] {
  color: var(--color-typo-Gold-200);
}

.DesktopLink--primary {
  background: var(--color-Gold-500)
    linear-gradient(to top, var(--color-Gold-200) 100%, transparent);
  background-position: bottom;
  background-repeat: repeat-x;

  /* set the height of the background hover color to 0, will be transitioned to 100% on hover/focus */
  background-size: 100% 0;
  transition: background-size var(--transition-default-duration-out)
    var(--transition-default-timing-out);
}

.DesktopLink--primary:hover,
.DesktopLink--primary:focus {
  /* animates the background cover from bottom to top */
  background-size: 100% 100%;
}

.DesktopLink-icon,
.DesktopLink-customIcon {
  display: inline-block;
}

.DesktopLink-customIcon {
  color: var(--color-typo-Gold-200);

  /* Sets the icon a little bit bigger than the inherited size */
  font-size: 1.25em;
}

.DesktopLink-icon {
  margin-bottom: var(--spacing-4);
}

.DesktopLink-icon,
.DesktopLink-customIcon--right {
  margin-left: var(--spacing-8);
}

.DesktopLink-customIcon--left {
  margin-right: var(--spacing-8);
}

@media (max-width: 48rem) {
  .DesktopLink {
    font: var(--typo-link-14);
    height: var(--HeaderNavigation-mobileHeight);
  }

  .DesktopLink--primary {
    padding: 0 var(--spacing-16);
  }

  .DesktopLink-subPageIndicators {
    display: none;
  }
}

@media (min-width: 48.0625rem) {
  .DesktopLink {
    /* Makes the button spans entire height of parent */
    height: 100%;
  }

  .DesktopLink--primary {
    margin-left: var(--spacing-20);
    padding: 0 var(--spacing-30);
  }

  .DesktopLink-label {
    text-align: center;
  }

  .DesktopLink-subPageIndicators {
    /* Centers the absolute positioned element */
    left: 50%;
    position: absolute;
    top: 0;

    /* Moves the indicators awaut from the border  */
    transform: translate(-50%, 100%);
  }

  .DesktopLink:not([aria-current="true"]) .DesktopLink-subPageIndicators {
    display: none;
  }
}

.DesktopLink .MobileDropdown-itemCaret {
  display: none;
}

@media (min-width: 64.0625rem) {
  .DesktopLink,
  .DesktopLink .MobileDropdown-itemLabel {
    padding: 0 var(--spacing-16);
  }
}

@media (min-width: 48.0625rem) and (max-width: 64rem) {
  .DesktopLink,
  .DesktopLink .MobileDropdown-itemLabel {
    font: var(--typo-link-16);
  }
}

@media (min-width: 64.0625rem) and (max-width: 90rem) {
  .DesktopLink,
  .DesktopLink .MobileDropdown-itemLabel {
    font: var(--typo-link-18);
  }
}

@media (min-width: 90.0625rem) {
  .DesktopLink,
  .DesktopLink .MobileDropdown-itemLabel {
    font: var(--typo-link-23);
  }
}
