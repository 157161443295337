/* @define PublicationsList */

@import "./publication/publication.css";

:root {
  --PublicationsList-spacing: calc(var(--spacing-20) * 2);
}

.PublicationsList,
.PublicationsList-item {
  box-sizing: border-box;
}

.PublicationsList-item {
  padding-bottom: var(--PublicationsList-spacing);
}

@media (min-width: 48.0625rem) {
  .PublicationsList {
    display: flex;
    flex-wrap: wrap;
  }

  .PublicationsList-item {
    /* Two columns */
    width: 50%;
  }

  .PublicationsList-item:nth-child(odd) {
    padding-right: var(--PublicationsList-spacing);
  }

  .PublicationsList-item:nth-child(even) {
    padding-left: var(--PublicationsList-spacing);
  }

  .PublicationsList-headline {
    color: var(--color-typo-Gold-200);
    font-size: var(--typo-link-23-font-size);
  }
}
