/* @define DefaultPage; weak */

:root {
  --DefaultPage-tabletBorder: var(--spacing-10);
  --DefaultPage-desktopBorder: var(--spacing-14);
  --DefaultPage-contentWrapper-mobileHeight: calc(
    var(--fullHeight) - var(--HeaderNavigation-mobileHeight) -
      var(--NavigationArrows-height)
  );
  --DefaultPage-contentWrapper-tabletHeight: calc(
    var(--fullHeight) - 2 * var(--DefaultPage-tabletBorder)
  );
  --DefaultPage-contentWrapper-desktopHeight: calc(
    var(--fullHeight) - 2 * var(--DefaultPage-desktopBorder)
  );
  --DefaultPage-content-maxWidth: 184rem;
  --DefaultPage-content-width: calc(100% - 2 * var(--spacing-72));
}

.DefaultPage {
  background: var(--color-Gray-700);
  overflow: hidden;
}

.DefaultPage-headerNavigation,
.DefaultPage-content,
.DefaultPage-navigationArrows,
.DefaultPage-footerNavigation {
  position: relative;
  z-index: 1;
}

.DefaultPage-contentWrapper {
  align-items: center;
  position: relative;
}

/* stylelint-disable-next-line */
.is-hidden-navigation .DefaultPage-contentWrapper {
  z-index: 2;
}

.DefaultPage-currentContent,
.DefaultPage-newContent {
  align-items: center;
  display: flex;
  grid-area: content;

  /* Spans entire height of container */
  height: 100%;
  justify-content: center;

  /* Spans entire width of container */
  width: 100%;
}

.DefaultPage-newContent {
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
}

@keyframes DefaultPageContentFadeInDelayed {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/* stylelint-disable */
.DefaultPage-currentContent.is-fadedOut {
  animation: fadeOut var(--transition-duration-1000) ease forwards;
}

.DefaultPage-currentContent,
.DefaultPage-newContent.is-fadedIn {
  animation: fadeIn var(--transition-duration-1000) ease forwards;
}

.DefaultPage-currentContent .DefaultPage-content,
.DefaultPage-newContent.is-fadedIn .DefaultPage-content {
  animation: DefaultPageContentFadeInDelayed var(--transition-duration-1500)
    ease forwards;
}
/* stylelint-enable */

.DefaultPage-content {
  align-items: center;
  box-sizing: border-box;

  /* stylelint-disable-next-line */
  display: -ms-grid;
  display: grid;
  gap: 0 var(--spacing-16);

  /* make sure the content element is not higher than the wrapper, so we get a scrollbar when the content inside is too long */
  overflow-y: auto;

  /* spans entire width of parent */
  width: 100%;
}

.DefaultPage-contentInner {
  grid-area: content;
  position: relative;
}

.DefaultPage-loaderWrapper {
  align-items: center;
  background-color: var(--color-Black);
  display: flex;

  /* Spans entire height of container */
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;

  /* Spans entire width of container */
  width: 100%;
  z-index: 1;
}

.DefaultPage-loaderWrapper[aria-hidden="true"] {
  display: none;
}

.DefaultPage-loaderWrapper.is-fadedIn {
  animation: fadeIn var(--transition-duration-1000) ease forwards;
}

.DefaultPage-loaderWrapper.is-fadedOut {
  animation: fadeOut var(--transition-duration-1000) ease forwards;
}

@media (max-width: 48rem) {
  .DefaultPage {
    height: calc(var(--fullHeight) - var(--HeaderNavigation-mobileHeight));
    padding: var(--HeaderNavigation-mobileHeight) 0 0;
  }

  .DefaultPage-contentWrapper {
    height: var(--DefaultPage-contentWrapper-mobileHeight);
  }

  .DefaultPage-content {
    grid-template-areas: "content content";
    grid-template-columns: repeat(2, 1fr);

    /* makes sure the content is scrollable, not the whole body */
    max-height: 100%;

    /* stylelint-disable-next-line */
    -ms-grid-columns: (1fr) [2];
  }

  .DefaultPage-contentInner {
    /* stylelint-disable-next-line */
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    padding: var(--spacing-24);
  }

  .DefaultPage-headerNavigation {
    left: 0;
    position: fixed;
    top: 0;

    /* Spans entire width of parent */
    width: 100%;

    /* Ensures nagivation on mobile is on top of elements like navigation arrows */
    z-index: 2;
  }
}

@media (min-width: 48.0625rem) {
  .DefaultPage {
    display: flex;
    flex-direction: column;
  }

  .DefaultPage-contentWrapper {
    display: flex;
    flex-grow: 1;
  }

  .DefaultPage-content {
    grid-template-columns: repeat(12, 1fr);

    /* stylelint-disable-next-line */
    -ms-grid-columns: (1fr) [12];
  }

  .DefaultPage-contentInner {
    padding: var(--spacing-24) 0;

    /* Spans entire width of parent */
    width: 100%;
  }

  .DefaultPage-navigationArrows {
    position: fixed;
  }
}

@media (min-width: 48.0625rem) and (max-width: 64rem) {
  .DefaultPage {
    height: var(--DefaultPage-contentWrapper-tabletHeight);
    margin: var(--DefaultPage-tabletBorder);
  }

  .DefaultPage-content {
    grid-template-areas: ". content content content content content content content content content content .";
    max-height: calc(
      var(--DefaultPage-contentWrapper-tabletHeight) -
        var(--HeaderNavigation-desktopHeight) -
        var(--HeaderNavigation-borderWidth)
    );
  }

  .DefaultPage-content--withFooter {
    max-height: calc(
      var(--DefaultPage-contentWrapper-tabletHeight) -
        var(--HeaderNavigation-desktopHeight) -
        var(--HeaderNavigation-borderWidth) - var(--FooterNavigation-height) -
        var(--FooterNavigation-borderWidth)
    );
  }

  .DefaultPage-contentInner {
    /* stylelint-disable-next-line */
    -ms-grid-column: 2;
    -ms-grid-column-span: 10;
  }
}

@media (min-width: 64.0625rem) {
  .DefaultPage {
    height: var(--DefaultPage-contentWrapper-desktopHeight);
    margin: var(--DefaultPage-desktopBorder);
  }

  .DefaultPage-content {
    grid-template-areas: ". . content content content content content content content content . .";
    max-height: calc(
      var(--DefaultPage-contentWrapper-desktopHeight) -
        var(--HeaderNavigation-desktopHeight) -
        var(--HeaderNavigation-borderWidth)
    );
  }

  .DefaultPage-content--withFooter {
    max-height: calc(
      var(--DefaultPage-contentWrapper-desktopHeight) -
        var(--HeaderNavigation-desktopHeight) -
        var(--HeaderNavigation-borderWidth) - var(--FooterNavigation-height) -
        var(--FooterNavigation-borderWidth)
    );
  }

  .DefaultPage-contentInner {
    /* stylelint-disable-next-line */
    -ms-grid-column: 3;
    -ms-grid-column-span: 8;
  }
}
