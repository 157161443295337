/* Mobile (375 x 667)
Tablet (1024 x 768) 64rem x 48rem
Desktop (1440 x 900)
Desktop (1920 x 1080)
Desktop (2560 x 1440) */

@custom-media --sm (max-width: 48rem);
@custom-media --sm-md (max-width: 64rem);
@custom-media --md (min-width: 48.0625rem) and (max-width: 64rem);
@custom-media --md-lg (min-width: 48.0625rem);
@custom-media --lg (min-width: 64.0625rem) and (max-width: 90rem);
@custom-media --lg-xl (min-width: 64.0625rem);
@custom-media --xl (min-width: 90.0625rem);

:root {
  --fullHeight: calc(var(--vh, 1vh) * 100);

  --spacing-4: 0.4rem;
  --spacing-8: 0.8rem;
  --spacing-10: 1rem;
  --spacing-12: 1.2rem;
  --spacing-14: 1.4rem;
  --spacing-16: 1.6rem;
  --spacing-20: 2rem;
  --spacing-24: 2.4rem;
  --spacing-30: 3rem;
  --spacing-45: 4.5rem;
  --spacing-60: 6rem;
  --spacing-72: 7.2rem;

  --color-White-rgb: 255, 255, 255;
  --color-White: rgba(var(--color-White-rgb), 1);
  --color-Black-rgb: 44, 44, 44;
  --color-Black: rgba(var(--color-Black-rgb), 1);
  --color-Gray-200-rgb: 219, 219, 219;
  --color-Gray-200: rgba(var(--color-Gray-200-rgb), 1);
  --color-Gray-300-rgb: 145, 145, 145;
  --color-Gray-300: rgba(var(--color-Gray-300-rgb), 1);
  --color-Gray-500-rgb: 111, 116, 118;
  --color-Gray-500: rgba(var(--color-Gray-500-rgb), 1);
  --color-Gray-700-rgb: 96, 98, 98;
  --color-Gray-700: rgba(var(--color-Gray-700-rgb), 1);
  --color-Gold-200-rgb: 204, 204, 0;
  --color-Gold-200: rgba(var(--color-Gold-200-rgb), 1);
  --color-Gold-500-rgb: 153, 153, 0;
  --color-Gold-500: rgba(var(--color-Gold-500-rgb), 1);

  --color-typo-White: var(--color-White);
  --color-typo-Black: var(--color-Black);
  --color-typo-Gold-200: var(--color-Gold-200);
  --color-typo-Gold-500: var(--color-Gold-500);
  --color-typo-Gray-300: var(--color-Gray-300);
  --color-typo-Gray-700: var(--color-Gray-700);

  --color-page-divider: var(--color-Gray-500);
  --color-background-overlay: rgba(0, 0, 0, 0.75);

  --font-family: "Open Sans", sans-serif;
  --font-family-condensed: "Open Sans Condensed", sans-serif;

  --typo-copy-font-weight: 600;
  --typo-copy-font-family: var(--font-family);

  --typo-copy-12-font-size: 1.2rem;
  --typo-copy-12-line-height: calc(2 / 1.2);
  --typo-copy-12: var(--typo-copy-font-weight) var(--typo-copy-12-font-size) /
    var(--typo-copy-12-line-height) var(--typo-copy-font-family);

  --typo-copy-14-font-size: 1.4rem; /* min 1.4rem max 2.0rem */
  --typo-copy-14-line-height: calc(2.5 / 1.4); /* min 2.2rem max 3.0rem */
  --typo-copy-14: var(--typo-copy-font-weight) var(--typo-copy-14-font-size) /
    var(--typo-copy-14-line-height) var(--typo-copy-font-family);

  --typo-copy-15-font-size: 1.5rem;
  --typo-copy-15-line-height: calc(2.5 / 1.5);
  --typo-copy-15: var(--typo-copy-font-weight) var(--typo-copy-15-font-size) /
    var(--typo-copy-15-line-height) var(--typo-copy-font-family);

  --typo-copy-16-font-size: 1.6rem;
  --typo-copy-16-line-height: calc(2.5 / 1.6);
  --typo-copy-16: var(--typo-copy-font-weight) var(--typo-copy-16-font-size) /
    var(--typo-copy-16-line-height) var(--typo-copy-font-family);

  --typo-copy-20-font-size: 2rem;
  --typo-copy-20-line-height: calc(3 / 2);
  --typo-copy-20: var(--typo-copy-font-weight) var(--typo-copy-20-font-size) /
    var(--typo-copy-20-line-height) var(--typo-copy-font-family);

  --typo-quote-font-weight: 400;
  --typo-quote-font-family: var(--font-family);

  --typo-quote-14-font-size: 1.4rem;
  --typo-quote-14-line-height: calc(2.5 / 1.4);
  --typo-quote-14: var(--typo-quote-font-weight) var(--typo-quote-14-font-size) /
    var(--typo-quote-14-line-height) var(--typo-quote-font-family);

  --typo-quote-16-font-size: 1.6rem;
  --typo-quote-16-line-height: calc(3 / 1.6);
  --typo-quote-16: var(--typo-quote-font-weight) var(--typo-quote-16-font-size) /
    var(--typo-quote-16-line-height) var(--typo-quote-font-family);

  --typo-quote-18-font-size: 1.8rem; /* min 1.8rem max 3.0rem */
  --typo-quote-18-line-height: calc(2.5 / 1.8); /* min 2.5rem max 4.0rem */
  --typo-quote-18: var(--typo-quote-font-weight) var(--typo-quote-18-font-size) /
    var(--typo-quote-18-line-height) var(--typo-quote-font-family);

  --typo-quote-22-font-size: 2.2rem; /* min 1.8rem max 3.0rem */
  --typo-quote-22-line-height: calc(3 / 2.2); /* min 2.5rem max 4.0rem */
  --typo-quote-22: var(--typo-quote-font-weight) var(--typo-quote-22-font-size) /
    var(--typo-quote-22-line-height) var(--typo-quote-font-family);

  --typo-quote-30-font-size: 3rem;
  --typo-quote-30-line-height: calc(4 / 3);
  --typo-quote-30-font-family: var(--font-family);
  --typo-quote-30: var(--typo-quote-font-weight) var(--typo-quote-30-font-size) /
    var(--typo-quote-30-line-height) var(--typo-quote-font-family);

  --typo-headline-font-weight: 700;
  --typo-headline-font-family: var(--font-family-condensed);

  --typo-headline-18-font-size: 1.8rem; /* min 1.8rem max 2.3rem */
  --typo-headline-18-line-height: 1;
  --typo-headline-18: var(--typo-headline-font-weight)
    var(--typo-headline-18-font-size) / var(--typo-headline-18-line-height)
    var(--typo-headline-font-family);

  --typo-headline-20-font-size: 2rem;
  --typo-headline-20-line-height: calc(3 / 2);
  --typo-headline-20: var(--typo-headline-font-weight)
    var(--typo-headline-20-font-size) / var(--typo-headline-20-line-height)
    var(--typo-headline-font-family);

  --typo-headline-25-font-size: 2.5rem;
  --typo-headline-25-line-height: calc(3 / 2.5);
  --typo-headline-25: var(--typo-headline-font-weight)
    var(--typo-headline-25-font-size) / var(--typo-headline-25-line-height)
    var(--typo-headline-font-family);

  --typo-headline-35-font-size: 3.5rem; /* min 2.5rem max 4.0rem */
  --typo-headline-35-line-height: 1;
  --typo-headline-35: var(--typo-headline-font-weight)
    var(--typo-headline-35-font-size) / var(--typo-headline-35-line-height)
    var(--typo-headline-font-family);

  --typo-headline-40-font-size: 4rem;
  --typo-headline-40-line-height: calc(4.5 / 4);
  --typo-headline-40: var(--typo-headline-font-weight)
    var(--typo-headline-40-font-size) / var(--typo-headline-40-line-height)
    var(--typo-headline-font-family);

  --typo-headline-45-font-size: 4.5rem; /* min 3.5rem max 8.5rem */
  --typo-headline-45-line-height: 1;
  --typo-headline-45: var(--typo-headline-font-weight)
    var(--typo-headline-45-font-size) / var(--typo-headline-45-line-height)
    var(--typo-headline-font-family);

  --typo-headline-65-font-size: 6.5rem; /* min 3.5rem max 8.5rem */
  --typo-headline-65-line-height: 1;
  --typo-headline-65: var(--typo-headline-font-weight)
    var(--typo-headline-65-font-size) / var(--typo-headline-65-line-height)
    var(--typo-headline-font-family);

  --typo-headline-85-font-size: 8.5rem; /* min 3.5rem max 8.5rem */
  --typo-headline-85-line-height: 1;
  --typo-headline-85: var(--typo-headline-font-weight)
    var(--typo-headline-85-font-size) / var(--typo-headline-85-line-height)
    var(--typo-headline-font-family);

  --typo-link-25-font-size: 2.5rem;
  --typo-link-25-line-height: calc(3.5 / 2.5);
  --typo-link-25: var(--typo-headline-font-weight) var(--typo-link-25-font-size) /
    var(--typo-link-25-line-height) var(--typo-headline-font-family);

  --typo-link-font-weight: 700;
  --typo-link-font-family: var(--font-family-condensed);

  --typo-link-14-font-size: 1.4rem;
  --typo-link-14-line-height: calc(2.2 / 1.4);
  --typo-link-14: var(--typo-link-font-weight) var(--typo-link-14-font-size) /
    var(--typo-link-14-line-height) var(--typo-link-font-family);

  --typo-link-16-font-size: 1.6rem; /* min 1.6rem max 8.5rem */
  --typo-link-16-line-height: calc(2.2 / 1.6);
  --typo-link-16-line-height-alt: calc(1.8 / 1.6);
  --typo-link-16: var(--typo-link-font-weight) var(--typo-link-16-font-size) /
    var(--typo-link-16-line-height) var(--typo-link-font-family);

  --typo-link-18-font-size: 1.8rem;
  --typo-link-18-line-height: calc(2.4 / 1.8);
  --typo-link-18: var(--typo-link-font-weight) var(--typo-link-18-font-size) /
    var(--typo-link-18-line-height) var(--typo-link-font-family);

  --typo-link-20-font-size: 2rem;
  --typo-link-20-line-height: calc(3 / 2);
  --typo-link-20: var(--typo-link-font-weight) var(--typo-link-18-font-size) /
    var(--typo-link-18-line-height) var(--typo-link-font-family);

  --typo-link-23-font-size: 2.3rem;
  --typo-link-23-line-height: calc(3.2 / 2.3);
  --typo-link-23: var(--typo-link-font-weight) var(--typo-link-23-font-size) /
    var(--typo-link-23-line-height) var(--typo-link-font-family);

  --typo-button-font-family: var(--font-family-condensed);

  --typo-button-14: var(--typo-link-font-weight) var(--typo-link-14-font-size) /
    var(--typo-link-14-line-height) var(--typo-link-font-family);

  --typo-button-16: var(--typo-link-font-weight) var(--typo-link-16-font-size) /
    var(--typo-link-16-line-height) var(--typo-link-font-family);

  --typo-button-18: var(--typo-link-font-weight) var(--typo-link-18-font-size) /
    var(--typo-link-18-line-height) var(--typo-link-font-family);

  --transition-duration-150: 0.15s;
  --transition-duration-300: 0.3s;
  --transition-duration-500: 0.5s;
  --transition-duration-750: 0.75s;
  --transition-duration-1000: 1s;
  --transition-duration-1500: 1.5s;

  --transition-default-duration-in: var(--transition-duration-150);
  --transition-default-timing-in: ease-out;
  --transition-default-duration-out: 0.15s;
  --transition-default-timing-out: ease-in;
  --transition-cubic-timing: cubic-bezier(0.69, 0.11, 0.49, 0.84);

  --box-shadow-default: 0 3px 6px rgba(0, 0, 0, 0.4);

  --dark-overlay-background: linear-gradient(
      to top,
      rgba(var(--color-Black-rgb), 0.5) 100%,
      transparent
    )
    repeat-x bottom;
  --dark-overlay-backgroundSize-initial: 100% 0%;
  --dark-overlay-backgroundSize-final: 100% 100%;
}
