/* @define LinkIndicator */

@media (min-width: 48.0625rem) {
  :root {
    --LinkIndicator-widthPointBottom: 1.6rem 1.25rem 0 1.25rem;
    --LinkIndicator-widthPointTop: 0 1.25rem 1.6rem 1.25rem;
  }

  .LinkIndicator {
    border-color: transparent;
    border-style: solid;
    display: none;
    height: 0;

    /* Centers the absolute positioned element */
    left: 50%;
    position: absolute;

    /* Leaves the indicator outside of the screen */
    transform: translateX(-50%);
    transition: transform var(--transition-default-duration-out)
      var(--transition-default-timing-out);
    width: 0;
  }

  /* stylelint-disable-next-line */
  [aria-current="true"] .LinkIndicator {
    display: block;
  }

  .LinkIndicator--bottom {
    border-bottom-color: var(--color-White);
    border-width: var(--LinkIndicator-widthPointTop);
    bottom: 0;
  }

  .LinkIndicator--top {
    border-top-color: var(--color-White);
    border-width: var(--LinkIndicator-widthPointBottom);
    top: 0;
  }
}
