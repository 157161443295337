/* @define MobileLegal */

@media (max-width: 48rem) {
  .MobileLegal {
    padding-bottom: var(--spacing-20);
  }

  .MobileLegal-paragraph,
  .MobileLegal-button {
    color: var(--color-typo-Gray-300);
    font: var(--typo-copy-12);
    text-align: center;
  }

  .MobileLegal-button {
    cursor: pointer;
    outline: none;
    position: relative;
    text-transform: uppercase;
  }

  .MobileLegal-label {
    bottom: calc(-1 * var(--spacing-10));
    position: absolute;
    text-decoration: underline;
    transition: opacity var(--transition-duration-300)
      var(--transition-default-timing-in);
  }

  .MobileLegal-button[aria-expanded="true"] .MobileLegal-label--closed,
  .MobileLegal-button[aria-expanded="false"] .MobileLegal-label--opened {
    opacity: 0;
  }

  .MobileLegal-button[aria-expanded="false"] .MobileLegal-label--closed,
  .MobileLegal-button[aria-expanded="true"] .MobileLegal-label--opened {
    opacity: 1;
  }
}

@media (min-width: 48.0625rem) {
  .MobileLegal {
    display: none;
  }
}
