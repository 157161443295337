/* @define Legal */

:root {
  --Legal-overlayBackground: rgba(var(--color-Black-rgb), 0.75);
  --Legal-closeButton-borderRadius: 50%;
  --Legal-list-buttonWidth: 6.2rem;
}

@media (max-width: 48rem) {
  .Legal {
    display: none;
  }
}

.Legal-overlay {
  background: transparent;
  position: relative;
}

.Legal-nav {
  align-items: center;
  display: flex;
}

.Legal-container {
  align-items: center;
  background-color: var(--color-White);
  bottom: 0;
  display: flex;
  height: var(--spacing-45);
  overflow: hidden;
  position: absolute;
  right: 0;

  /* Hides most of the list but the first item */
  transform: translateX(calc(100% - var(--Legal-list-buttonWidth)));
  z-index: 12;
}

.Legal-list {
  display: flex;
  padding: 0 var(--spacing-10);
}

.Legal-item {
  padding-left: var(--spacing-10);
  padding-right: var(--spacing-10);
}

.Legal-openButton {
  margin-left: var(--spacing-16);
}

.Legal-openButton,
.Legal-closeButton {
  margin-right: var(--spacing-10);
}

.Legal-openButton,
.Legal-link {
  color: var(--color-typo-Gray-700);
  font: var(--typo-link-14);
  text-transform: uppercase;
}

.Legal-openButton:hover,
.Legal-openButton:focus,
.Legal-link:hover,
.Legal-link:focus {
  color: var(--color-typo-Gold-500);
}

.Legal-link {
  display: block;
  text-decoration: none;
}

.Legal-openButton,
.Legal-closeButton {
  cursor: pointer;
  outline: none;
}

.Legal-closeButton {
  align-items: center;
  background-color: var(--color-Gold-500);
  border-radius: var(--Legal-closeButton-borderRadius);
  color: var(--color-typo-White);
  display: flex;
  font-size: var(--spacing-10);
  height: var(--spacing-24);
  justify-content: center;
  width: var(--spacing-24);
}

.Legal-closeButton:hover,
.Legal-closeButton:active {
  background-color: var(--color-Gold-200);
}

.Legal.is-transitioning .Legal-container,
.Legal.is-transitioning .Legal-nav {
  transition: transform var(--transition-duration-500)
    var(--transition-cubic-timing);
}

.Legal.is-transitioning .Legal-overlay {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: background var(--transition-duration-500)
    var(--transition-cubic-timing);
  z-index: 11;
}

.Legal.is-expanded .Legal-overlay {
  background: var(--Legal-overlayBackground);
}

.Legal.is-expanded .Legal-container {
  transform: translateX(var(--Legal-list-buttonWidth));
}

.Legal.is-expanded .Legal-nav {
  transform: translateX(calc(-1 * var(--Legal-list-buttonWidth)));
}
