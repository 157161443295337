/* @define Accordion */

@import "./item/item.css";

@media (max-width: 64rem) {
  .Accordion-listItem {
    margin-top: var(--spacing-16);
  }

  .Accordion-list:first-child .Accordion-listItem:first-child {
    margin-top: 0;
  }
}

@media (min-width: 64.0625rem) {
  .Accordion {
    display: flex;

    /* Spans entire width of parent */
    width: 100%;
  }

  .Accordion-list:not(:last-child) {
    padding-right: var(--spacing-16);
  }

  .Accordion-list {
    flex: 1;
  }

  .Accordion-listItem:not(:first-child) {
    margin-top: var(--spacing-16);
  }
}
