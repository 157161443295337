/* @define BackgroundMedia; weak */

:root {
  --BackgroundMedia-pageSize: 100%;
}

@keyframes zoomBg {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(2);
  }

  100% {
    transform: scale(1);
  }
}

.BackgroundMedia {
  box-sizing: border-box;
  left: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: var(--BackgroundMedia-pageSize);
}

.BackgroundMedia-image {
  height: var(--BackgroundMedia-pageSize);
  object-fit: cover;
  object-position: center;
  transform-origin: center top;
  width: var(--BackgroundMedia-pageSize);
}

.BackgroundMedia-image.is-animated {
  animation: zoomBg 120s linear;
}

@media (max-width: 48rem) {
  .BackgroundMedia {
    height: calc(
      var(--BackgroundMedia-pageSize) - var(--NavigationArrows-height)
    );
  }
}

@media (min-width: 48.0625rem) and (max-width: 64rem) {
  .BackgroundMedia {
    height: calc(
      var(--BackgroundMedia-pageSize) - 2 * var(--DefaultPage-tabletBorder)
    );
    margin: var(--DefaultPage-tabletBorder);

    /* set width explicitely to make sure the image doesnt overflow when zooming in */
    width: calc(100% - 2 * var(--DefaultPage-tabletBorder));
  }
}

@media (min-width: 64.0625rem) {
  .BackgroundMedia {
    height: calc(
      var(--BackgroundMedia-pageSize) - 2 * var(--DefaultPage-desktopBorder)
    );
    margin: var(--DefaultPage-desktopBorder);

    /* set width explicitely to make sure the image doesnt overflow when zooming in */
    width: calc(100% - 2 * var(--DefaultPage-desktopBorder));
  }
}
