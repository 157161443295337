@keyframes slideInFromTop {
  from {
    /* Slides entire element height */
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes slideOutFromTop {
  from {
    transform: translateY(0);
  }
  
  to {
    /* Slides entire element height */
    transform: translateY(-100%);
  }
}

@keyframes slideInFromLeft {
  from {
    /* Slides entire width of element */
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes slideOutFromLeft {
  from {
    transform: translateX(0);
  }
  
  to {
    /* Slides entire width of element */
    transform: translateX(-100%);
  }
}

@keyframes slideInFromRight {
  from {
    /* Slides entire width of element */
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes slideOutFromRight {
  from {
    transform: translateX(0);
  }
  
  to {
    /* Slides entire width of element */
    transform: translateX(100%);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
