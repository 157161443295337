/* open-sans-condensed-700 - latin */
@font-face {
  font-display: swap;
  font-family: "Open Sans Condensed";
  font-style: normal;
  font-weight: 700;
  src: local(""),
    url("../../fonts/open-sans-condensed-v15-latin-700.woff2") format("woff2"),
    url("../../fonts/open-sans-condensed-v15-latin-700.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* open-sans-regular - latin */
@font-face {
  font-display: swap;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: local(""),
    url("../../fonts/open-sans-v18-latin-regular.woff2") format("woff2"),
    url("../../fonts/open-sans-v18-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* open-sans-regular - latin */
@font-face {
  font-display: swap;
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 600;
  src: local(""),
    url("../../fonts/open-sans-v18-latin-600italic.woff2") format("woff2"),
    url("../../fonts/open-sans-v18-latin-600italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* open-sans-600 - latin */
@font-face {
  font-display: swap;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  src: local(""),
    url("../../fonts/open-sans-v18-latin-600.woff2") format("woff2"),
    url("../../fonts/open-sans-v18-latin-600.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* open-sans-600 - latin */
@font-face {
  font-display: swap;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  src: local(""),
    url("../../fonts/open-sans-v18-latin-700.woff2") format("woff2"),
    url("../../fonts/open-sans-v18-latin-700.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
