/* @define Teaser */

:root {
  --Teaser-contentWrapperGradient: linear-gradient(
    to top,
    rgba(var(--color-Black-rgb), 0.75),
    transparent 50%
  );
  --Teaser-borderWidth: 0.1rem;
}

.Teaser {
  border: var(--Teaser-borderWidth) solid var(--color-Gold-200);
  box-sizing: border-box;
  display: block;
  overflow: hidden;

  /* Keeps aspect ratio */
  padding-bottom: 59.8%;
  position: relative;
}

.Teaser-title {
  line-height: 1 !important;
  position: relative;
  text-transform: uppercase;
}

:where(.HomePage, .NewsPage) .Teaser-title {
  text-transform: none;
}

.Teaser-link {
  color: var(--color-typo-Gold-200);
  display: block;
  line-height: 1 !important;
  text-decoration: underline;

  /* Moves the link further down and then transitions to 0 */
  transform: translateY(250%);
}

.Teaser-title {
  transition: bottom var(--transition-duration-300)
    var(--transition-default-timing-in);
}

.Teaser-link {
  transition: transform var(--transition-duration-300)
    var(--transition-default-timing-in);
}

.Teaser-image {
  /* Spans entire height of parent container */
  height: 100%;
  left: 0;
  object-fit: cover;
  object-position: center;
  position: absolute;
  top: 0;

  /* Spans entire width of parent container */
  width: 100%;
}

.Teaser-contentWrapper {
  background: var(--Teaser-contentWrapperGradient);

  /* Spans entire height of parent container */
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;

  /* Spans entire width of parent container */
  width: 100%;
}

.Teaser-content {
  background: var(--dark-overlay-background);
  background-size: var(--dark-overlay-backgroundSize-initial);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  /* Spans entire height of parent container */
  height: 100%;
  justify-content: flex-end;
  padding: var(--spacing-16);
  transition: background-size var(--transition-duration-300)
    var(--transition-default-timing-in);
}

.Teaser:hover .Teaser-content,
.Teaser:focus .Teaser-content {
  background-size: var(--dark-overlay-backgroundSize-final);
}

.Teaser:hover .Teaser-title,
.Teaser:focus .Teaser-title {
  bottom: 0;
}

.Teaser:hover .Teaser-link,
.Teaser:focus .Teaser-link {
  transform: translateY(0);
}

.Teaser-linkOverlay {
  display: block;

  /* Spans entire height of parent container */
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;

  /* Spans entire width of parent container */
  width: 100%;
}

.Teaser-headline {
  font: var(--typo-headline-25);
  margin-top: var(--spacing-20);
}

.Teaser-description {
  font: var(--typo-copy-14);
  margin-top: var(--spacing-16);
  padding-right: var(--spacing-20);
}

.Teaser-type {
  color: var(--color-Gold-200);
  margin-top: 15px;
  text-decoration: underline;
}

@media (max-width: 48rem) {
  .Teaser-title {
    font: var(--typo-headline-25);
  }
}

@media (max-width: 64rem) {
  .Teaser-title {
    bottom: calc(-1 * var(--spacing-20));
  }

  .Teaser-link {
    font: var(--typo-link-14);
  }
}

@media (min-width: 48.0625rem) and (max-width: 64rem) {
  .Teaser-title {
    font: var(--typo-headline-20);
  }
}

@media (min-width: 64.0625rem) and (max-width: 120rem) {
  .Teaser-title {
    bottom: calc(
      -1 * var(--typo-link-18-font-size) * var(--typo-link-18-line-height)
    );
    font: var(--typo-headline-25);
  }

  .Teaser-link {
    font: var(--typo-link-18);
  }
}

@media (min-width: 120.0625rem) {
  .Teaser-title {
    bottom: calc(
      -1 * var(--typo-link-23-font-size) * var(--typo-link-23-line-height)
    );
    font: var(--typo-headline-40);
  }

  .Teaser-link {
    font: var(--typo-link-23);
  }
}
