/* @define AccordionItem; weak */

:root {
  --AccordionItem-borderWidth: 0.1rem;
  --AccordionItem-iconSize: 1.1rem;
}

.AccordionItem {
  border: var(--AccordionItem-borderWidth) solid var(--color-Gold-200);
  border-bottom: none;
  transition: background-color var(--transition-duration-300)
    var(--transition-default-timing-in);
}

.AccordionItem-button {
  color: var(--color-typo-White);
  cursor: pointer;
  display: block;
  margin: 0;
  outline: none;
  padding: var(--spacing-16);
  padding-right: calc(2 * var(--spacing-16));
  position: relative;
  text-align: left;
  transition: color var(--transition-default-duration-out)
    var(--transition-default-timing-out);

  /* Spans the entire width of the parent container */
  width: 100%;
}

.AccordionItem-button:hover,
.AccordionItem-button:focus {
  color: var(--color-typo-Gold-200);
  transition-duration: var(--transition-default-duration-in);
  transition-timing-function: var(--transition-default-timing-in);
}

.AccordionItem-icon {
  color: var(--color-typo-Gold-200);
  font-size: var(--AccordionItem-iconSize);
  position: absolute;
  right: var(--spacing-16);

  /* Vertically centers the icon (with translateY) */
  top: 50%;

  /* Vertically centers the icon (with top: 50%) */
  transform: translateY(-50%);
}

.AccordionItem-iconPlus {
  transition: opacity var(--transition-duration-300)
    var(--transition-default-timing-in);
}

.AccordionItem.is-expanded .AccordionItem-iconPlus {
  opacity: 0;
}

.AccordionItem-content {
  border: var(--AccordionItem-borderWidth) solid var(--color-Gold-200);
  border-top: none;
  max-height: 0;
  overflow: hidden;
  padding: 0 var(--spacing-16) 0;
  transition-duration: var(--transition-duration-300);
  transition-property: background-color, max-height, padding-bottom;
  transition-timing-function: var(--transition-default-timing-in);
}

.AccordionItem-contentWrapper {
  opacity: 0;
  transition: opacity var(--transition-duration-300)
    var(--transition-default-timing-in);
}

.AccordionItem-contentWrapper > * {
  text-align: left;
}

.AccordionItem.is-expanded
  + .AccordionItem-content
  .AccordionItem-contentWrapper {
  opacity: 1;
}

.AccordionItem-contentWrapper > * + * {
  margin-top: var(--spacing-24);
}

.AccordionItem.is-expanded,
.AccordionItem.is-expanded + .AccordionItem-content {
  background-color: var(--color-background-overlay);
}

.AccordionItem.is-expanded + .AccordionItem-content {
  padding-bottom: var(--spacing-16);
}

@media (max-width: 64rem) {
  .AccordionItem-button {
    font: var(--typo-link-18);
  }
}

@media (min-width: 64.0625rem) {
  .AccordionItem-button {
    font: var(--typo-link-23);
  }
}
