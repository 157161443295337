/* @define SubPageIndicators */

:root {
  --SubPageIndicators-animation-delay: var(--transition-duration-500);
  --SubPageIndicators-animation-difference: 0.1s;
}

@keyframes SubPageIndicatorsFadeIn {
  from {
    opacity: 0;

    /* Slides entire height of element */
    transform: translateY(100%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.SubPageIndicators {
  display: flex;

  /* Makes the squares 10x10px */
  font-size: 1rem;
  margin-bottom: var(--spacing-8);
}

.SubPageIndicators.is-animated .SubPageIndicators-item {
  animation: SubPageIndicatorsFadeIn var(--transition-duration-500) ease
    forwards;
  opacity: 0;

  /* Slides entire height of element */
  transform: translateY(100%);
}

.SubPageIndicators.is-animated .SubPageIndicators-item:nth-child(1) {
  animation-delay: var(--SubPageIndicators-animation-delay);
}

.SubPageIndicators.is-animated .SubPageIndicators-item:nth-child(2) {
  animation-delay: calc(
    var(--SubPageIndicators-animation-delay) +
      calc(var(--SubPageIndicators-animation-difference) * 1)
  );
}

.SubPageIndicators.is-animated .SubPageIndicators-item:nth-child(3) {
  animation-delay: calc(
    var(--SubPageIndicators-animation-delay) +
      calc(var(--SubPageIndicators-animation-difference) * 2)
  );
}

.SubPageIndicators.is-animated .SubPageIndicators-item:nth-child(4) {
  animation-delay: calc(
    var(--SubPageIndicators-animation-delay) +
      calc(var(--SubPageIndicators-animation-difference) * 3)
  );
}

.SubPageIndicators.is-animated .SubPageIndicators-item:nth-child(5) {
  animation-delay: calc(
    var(--SubPageIndicators-animation-delay) +
      calc(var(--SubPageIndicators-animation-difference) * 4)
  );
}

.SubPageIndicators-item:not(:last-child) {
  margin-right: var(--spacing-10);
}
