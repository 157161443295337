/* @define Loader */

@keyframes LoaderSpin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

:root {
  --Loader-outlineSize: 1.1em;
  --Loader-size: 10rem;
  --Loader-fontSize: 1rem;
}

.Loader,
.Loader::after {
  /* Makes it round */
  border-radius: 50%;
  height: var(--Loader-size);
  width: var(--Loader-size);
}

.Loader {
  animation: LoaderSpin var(--transition-duration-1000) infinite linear;
  border-bottom: var(--Loader-outlineSize) solid rgba(204, 204, 0, 0.2);
  border-left: var(--Loader-outlineSize) solid #cc0;
  border-right: var(--Loader-outlineSize) solid rgba(204, 204, 0, 0.2);
  border-top: var(--Loader-outlineSize) solid rgba(204, 204, 0, 0.2);
  font-size: var(--Loader-fontSize);
  position: relative;

  /* Puts the text outside of the viewport */
  text-indent: -9999em;
}
