/* @define HeaderNavigation */

@import "./mobile-dropdown/mobile-dropdown.css";
@import "./desktop-dropdown/desktop-dropdown.css";

:root {
  --HeaderNavigation-mobileHeight: 7rem;
  --HeaderNavigation-desktopHeight: 10rem;
  --HeaderNavigation-burgerButtonSize: 2rem;
  --HeaderNavigation-mobileMenuDisplacement: 15%;
  --HeaderNavigation-borderWidth: 1px;
  --HeaderNavigation-toggleEl-width: 2rem;
  --HeaderNavigation-toggleEl-height: 0.3rem;
  --HeaderNavigation-toggleEl-spacing: var(--spacing-4);
  --HeaderNavigation-toggleEl-transformY: calc(
    var(--HeaderNavigation-toggleEl-spacing) +
      var(--HeaderNavigation-toggleEl-height)
  );
  --HeaderNavigation-wrapper-transition-duration: 0.75s;
  --HeaderNavigation-tabletLogoWidth: 12rem;
  --HeaderNavigation-largeLogoWidth: 17.5rem;
}

/* stylelint-disable */
.HeaderNavigation {
  animation: var(--transition-duration-750) slideInFromTop ease forwards;
}

.is-hidden-navigation .HeaderNavigation {
  animation: var(--transition-duration-750) slideOutFromTop ease forwards;
}
/* stylelint-enable */

.HeaderNavigation-wrapper {
  display: flex;
}

.HeaderNavigation-logo {
  display: flex;
  flex: 1;
}

.HeaderNavigation-logoLink {
  align-items: center;
  display: flex;
  padding: 0 var(--spacing-24);
}

.HeaderNavigation-logoImage {
  height: var(--spacing-30);
}

.HeaderNavigation-listDesktop {
  margin-left: auto;
}

.HeaderNavigation-listItem:last-child .MobileDropdownItem {
  border-bottom-color: transparent;
}

@media (max-width: 48rem) {
  .HeaderNavigation-logo {
    border-bottom: var(--HeaderNavigation-borderWidth) solid
      var(--color-page-divider);
  }

  .HeaderNavigation-listDesktop
    .HeaderNavigation-listItem:not(.HeaderNavigation-cta) {
    display: none;
  }

  .HeaderNavigation-wrapper {
    /* give element a background image that covers the whole element */
    background-image: linear-gradient(
      to bottom,
      var(--color-Black) 100%,
      transparent
    );
    background-repeat: repeat-x;

    /* but set its size to 0 to be able to animate it */
    background-size: 100% 0;
    flex-wrap: wrap;
  }

  .HeaderNavigation-wrapper.is-animated {
    transition: background-size
      var(--HeaderNavigation-wrapper-transition-duration) ease;
  }

  .HeaderNavigation-wrapper.is-active {
    /* background should fill whole viewport */
    background-size: 100% 100%;
  }

  .HeaderNavigation-menu {
    align-items: center;
    background-color: var(--color-White);
    color: var(--color-typo-Gray-700);
    display: flex;
    flex-direction: column;
    font-size: var(--HeaderNavigation-burgerButtonSize);
    height: var(--HeaderNavigation-mobileHeight);
    justify-content: center;
    width: var(--HeaderNavigation-mobileHeight);
  }

  .HeaderNavigation-listMobileWrapper {
    display: flex;
    flex-direction: column;

    /* make sure the mobile navigation always covers the full viewport */
    height: calc(var(--fullHeight) - var(--HeaderNavigation-mobileHeight));
    justify-content: space-between;
    overflow-y: auto;
    position: relative;

    /* parent is a flex element, so this one does not automatically have full width */
    width: 100%;
  }

  /* stylelint-disable */
  .is-animatedIn + .HeaderNavigation-listMobileWrapper,
  .is-animatedOut + .HeaderNavigation-listMobileWrapper {
    /* stylelint-enable */
    transition: opacity var(--HeaderNavigation-wrapper-transition-duration),
      height var(--HeaderNavigation-wrapper-transition-duration);
  }

  .HeaderNavigation-menu[aria-expanded="false"]
    ~ .HeaderNavigation-listMobileWrapper {
    height: 0;
    opacity: 0;
    pointer-events: none;
  }

  .HeaderNavigation-menu[aria-expanded="true"]
    ~ .HeaderNavigation-listMobileWrapper {
    opacity: 1;
  }

  .HeaderNavigation-listMobile {
    box-sizing: border-box;
    margin: var(--spacing-24);
    position: relative;
  }

  .HeaderNavigation-listMobile.is-fadedIn {
    animation: fadeIn var(--transition-duration-300) forwards;
  }

  .HeaderNavigation-listMobile.is-fadedOut {
    animation: fadeOut var(--transition-duration-300) forwards;
  }

  .HeaderNavigation-listMobile .HeaderNavigation-listItem:first-child {
    margin-top: calc(-1 * var(--spacing-16));
  }

  .HeaderNavigation-listMobile .HeaderNavigation-listItem:not(:first-child) {
    border-top: var(--MobileDropdownItem-borderWidth) solid
      var(--color-Gray-500);
  }

  .HeaderNavigation-listMobile .HeaderNavigation-listItem:last-child {
    border-bottom: var(--MobileDropdownItem-borderWidth) solid
      var(--color-Gray-500);
  }

  .HeaderNavigation-toggleEl {
    background: var(--color-Gray-500);
    display: block;
    height: var(--HeaderNavigation-toggleEl-height);
    width: var(--HeaderNavigation-toggleEl-width);
  }

  .HeaderNavigation-toggleEl:nth-child(2) {
    margin: var(--spacing-4) 0;
  }

  @keyframes HeaderNavigationToggle1in {
    50% {
      transform: translateY(var(--HeaderNavigation-toggleEl-transformY));
    }

    100% {
      transform: translateY(var(--HeaderNavigation-toggleEl-transformY))
        rotate(45deg);
    }
  }
  @keyframes HeaderNavigationToggle2in {
    50% {
      opacity: 0;
    }

    100% {
      opacity: 0;
    }
  }
  @keyframes HeaderNavigationToggle3in {
    50% {
      transform: translateY(
        calc(-1 * var(--HeaderNavigation-toggleEl-transformY))
      );
    }

    100% {
      transform: translateY(
          calc(-1 * var(--HeaderNavigation-toggleEl-transformY))
        )
        rotate(-45deg);
    }
  }

  @keyframes HeaderNavigationToggle1out {
    0% {
      transform: translateY(var(--HeaderNavigation-toggleEl-transformY))
        rotate(45deg);
    }

    50% {
      transform: translateY(var(--HeaderNavigation-toggleEl-transformY));
    }

    100% {
      transform: translateY(0) rotate(0);
    }
  }
  @keyframes HeaderNavigationToggle2out {
    0% {
      opacity: 0;
    }

    50% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
  @keyframes HeaderNavigationToggle3out {
    0% {
      transform: translateY(
          calc(-1 * var(--HeaderNavigation-toggleEl-transformY))
        )
        rotate(-45deg);
    }

    50% {
      transform: translateY(
        calc(-1 * var(--HeaderNavigation-toggleEl-transformY))
      );
    }

    100% {
      transform: translateY(0) rotate(0);
    }
  }

  .HeaderNavigation-toggleEl {
    animation: 1s ease forwards;
  }

  /* stylelint-disable */
  .is-animatedIn .HeaderNavigation-toggleEl:nth-child(1) {
    animation-name: HeaderNavigationToggle1in;
  }

  .is-animatedIn .HeaderNavigation-toggleEl:nth-child(2) {
    animation-name: HeaderNavigationToggle2in;
  }

  .is-animatedIn .HeaderNavigation-toggleEl:nth-child(3) {
    animation-name: HeaderNavigationToggle3in;
  }

  .is-animatedOut .HeaderNavigation-toggleEl:nth-child(1) {
    animation-name: HeaderNavigationToggle1out;
  }

  .is-animatedOut .HeaderNavigation-toggleEl:nth-child(2) {
    animation-name: HeaderNavigationToggle2out;
  }

  .is-animatedOut .HeaderNavigation-toggleEl:nth-child(3) {
    animation-name: HeaderNavigationToggle3out;
  }
  /* stylelint-enable */
}

@media (min-width: 48.0625rem) {
  .HeaderNavigation-menu,
  .HeaderNavigation-listMobileWrapper {
    display: none;
  }

  .HeaderNavigation-wrapper {
    border-bottom: var(--HeaderNavigation-borderWidth) solid
      var(--color-page-divider);
    height: var(--HeaderNavigation-desktopHeight);
  }

  .HeaderNavigation-listDesktop {
    display: flex;
  }

  .HeaderNavigation-logoImage {
    height: var(--spacing-60);
    width: var(--HeaderNavigation-tabletLogoWidth);
  }
}

@media (min-width: 90.0625rem) {
  .HeaderNavigation-logoImage {
    width: var(--HeaderNavigation-largeLogoWidth);
  }
}
