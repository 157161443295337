/* @define RichText; weak */

:root {
  --RichText-max-width: 52em;
}

.RichText {
  margin: 0 auto;
  max-width: var(--RichText-max-width);
  text-align: center;
}

/* stylelint-disable */
.Accordion .RichText,
.TwoColumn .RichText {
  /* stylelint-enable */
  max-width: none;
  text-align: left;
}

.RichText a {
  color: var(--color-typo-Gold-200);
  font-weight: 700;
}

.RichText a:hover,
.RichText a:focus {
  color: var(--color-typo-Gold-500);
}

.RichText a[href^="http"][target="_blank"]::before {
  content: url("../../../svgs/icons/icon_external_link_gold.svg");

  /* Gives about half character spacing between icon and word */
  margin-right: 0.5ch;
  position: relative;

  /* Compensates for empty space that makes the icon too tall */
  top: 0.1em;
}

.RichText a + a::before {
  /* Gives about one character spacing between icon and word */
  margin-left: 1ch;
}

.RichText ul > li:not(:first-child),
.RichText ol > li:not(:first-child) {
  margin-top: var(--spacing-10);
}

.RichText ul,
.RichText ol {
  padding-left: var(--spacing-20);
  text-align: left;
}

.RichText ol {
  list-style: decimal;
}

.RichText ul {
  list-style: disc;
}

.RichText li ul {
  list-style: circle;
}

.RichText li ul,
.RichText li ol {
  margin-left: var(--spacing-16);
  margin-top: var(--spacing-10);
}

.RichText h3,
.RichText b,
.RichText strong {
  font-weight: 700;
}

.RichText picture,
.RichText img,
.RichText svg,
.RichText iframe,
.RichText video {
  height: 100%;
  max-width: 100%;
}

.RichText > * + * {
  /* use line-height of copy for spacing between paragraphs/lists to keep vertical rhythm */
  margin-top: calc(var(--typo-copy-16-line-height) * 1em);
}

.RichText-teaserHeadline h2 {
  font-size: var(--typo-headline-25-font-size);
}

@media (max-width: 48rem) {
  .RichText {
    font: var(--typo-copy-14);
  }
}

@media (min-width: 48.0625rem) and (max-width: 64rem) {
  .RichText {
    font: var(--typo-copy-15);
  }
}

@media (min-width: 64.0625rem) and (max-width: 120rem) {
  .RichText {
    font: var(--typo-copy-16);
  }
}

@media (min-width: 120.0625rem) {
  .RichText {
    font: var(--typo-copy-20);
  }
}
