/* @define Headline */

:root {
  --Headline-sublineFontSize: 0.4em;
}

.Headline {
  display: flex;
  flex-direction: column;
  text-align: center;
  text-transform: uppercase;
}

:where(.PublicationDetailPage, .NewsDetailPage, .EventDetailPage, .NewsPage) .Headline {
  text-transform: none;
}

.Headline-subline {
  font-size: var(--Headline-sublineFontSize);
}

@media (max-width: 48rem) {
  .Headline {
    font: var(--typo-headline-35);
  }
}

@media (min-width: 48.0625rem) and (max-width: 64rem) {
  .Headline {
    font: var(--typo-headline-40);
  }
}

@media (min-width: 64.0625rem) and (max-width: 90rem) {
  .Headline {
    font: var(--typo-headline-45);
  }
}

@media (min-width: 90.0625rem) and (max-width: 120rem) {
  .Headline {
    font: var(--typo-headline-65);
  }
}

@media (min-width: 120.0625rem) {
  .Headline {
    font: var(--typo-headline-85);
  }
}
