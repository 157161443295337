/* @define MobileDropdownItem */

:root {
  --MobileDropdownItem-borderWidth: 1px;
  --MobileDropdownItem-height: 5.2rem;
}

.MobileDropdownItem {
  display: flex;
  text-align: left;
  text-decoration: none;
  text-transform: uppercase;

  /* Buttons with display: flex do not span entire width */
  width: 100%;
}

.MobileDropdownItem--lvl0 {
  font: var(--typo-link-25);
  padding: var(--spacing-30) 0;
  transition: padding var(--transition-default-duration-out) var(--transition-default-timing-out);
}

.MobileDropdownItem--lvl0[aria-expanded="true"] {
  padding: var(--spacing-30) 0 var(--spacing-4);
}

.MobileDropdownItem,
.MobileDropdownItem:visited {
  color: inherit;
}

.MobileDropdownItem:not(.MobileDropdownItem--lvl0) {
  font: var(--typo-link-20);
  line-height: var(--MobileDropdownItem-height);
}

.MobileDropdownItem[aria-current] {
  color: var(--color-typo-Gold-200);
}

/* stylelint-disable-next-line */
.MobileDropdownItem[aria-expanded="true"] .MobileDropdown-itemCaret {
  transform: rotate(-180deg);
}
