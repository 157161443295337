/* @define MobileDropdown; weak */

@import "./item/item.css";

:root {
  --MobileDropdown-animation-duration: var(--transition-duration-500);
}

.MobileDropdown-nav
 {
  max-height: 0;
  overflow: hidden;
}

.MobileDropdown-nav {
  opacity: 0;
  transition: all var(--MobileDropdown-animation-duration) ease;
}

.MobileDropdown-nav.is-active {
  opacity: 1;
  transition: all var(--MobileDropdown-animation-duration) ease;
}

.MobileDropdown-list {
  padding: 0 var(--spacing-16) var(--spacing-16);
}

.MobileDropdown-list .MobileDropdown-list {
  padding: 0 var(--spacing-16);
}

.MobileDropdown-listItem:not(:first-child) {
  border-top: var(--MobileDropdownItem-borderWidth) solid var(--color-Gray-500);
}

.MobileDropdown-listItem:last-child .MobileDropdownItem {
  border-bottom-color: transparent;
}

.MobileDropdown-itemLabel,
.MobileDropdown-itemCaret {
  display: block;
}

.MobileDropdown-itemLabel {
  overflow: hidden;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.MobileDropdown-listItemDropdown {
  align-items: center;
}

.MobileDropdown-itemCaret {
  color: var(--color-typo-Gold-500);
  margin-left: var(--spacing-16);
  transition: transform var(--transition-default-duration-out)
    var(--transition-default-timing-out);
}

.MobileDropdown-itemCaret[aria-expanded="true"] {
  transform: rotate(180deg);
}


.MobileDropdown-itemCaret {
  /* Makes the size of the caret fixed */
  font-size: 2rem;
}

@media (min-width: 48.0625rem) {
  .MobileDropdown-listItemDropdown {
    position: relative;
  }

  .MobileDropdown-itemCaret {
    left: 260px;
    position: absolute;
  }

  .MobileDropdown-list {
    max-width: 300px;
    min-width: 300px;
  }

  .MobileDropdown-itemLabel {
    white-space: initial;
  }
}

@media (max-width: 48rem) {
  .MobileDropdown-listItemDropdown {
    justify-content: space-between;
  }
}
