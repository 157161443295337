/* @define TwoColumn */

.TwoColumn {
  /* fix for IE11 */
  width: 100%;
}

@media (max-width: 64rem) {
  .TwoColumn-left {
    padding-bottom: var(--spacing-24);
  }
}

@media (min-width: 64.01rem) {
  .TwoColumn {
    display: flex;
  }

  .TwoColumn-left,
  .TwoColumn-right {
    box-sizing: border-box;
    flex: 1;
  }

  .TwoColumn-left {
    padding-right: var(--spacing-24);
  }

  .TwoColumn-right {
    padding-left: var(--spacing-24);
  }
}
