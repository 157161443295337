/* @define TeaserGroup */

@import "./teaser-regular/teaser-regular.css";
@import "./teaser-video/teaser-video.css";
@import "./teaser-pagination/teaser-pagination.css";

:root {
  --TeaserGroup-item-width-odd: 33.33%;
  --TeaserGroup-item-width-even: 25%;
}

.TeaserGroup {
  /* spans entire width of parent */
  width: 100%;
}

.TeaserGroup-item {
  box-sizing: border-box;
  padding-bottom: var(--spacing-16);

  /* spans entire width of parent */
  width: 100%;
}

@media (min-width: 48.0625rem) {
  .TeaserGroup {
    display: flex;
    flex-wrap: wrap;
  }

  .TeaserGroup--centered {
    justify-content: center;
  }

  .TeaserGroup-item--odd {
    flex: 0 0 var(--TeaserGroup-item-width-odd);
  }
}

@media (min-width: 48.0625rem) and (max-width: 80rem) {
  .TeaserGroup-item--even {
    flex: 0 0 calc(2 * var(--TeaserGroup-item-width-even));
  }
}

@media (min-width: 80.0625rem) {
  .TeaserGroup-item--even {
    flex: 0 0 var(--TeaserGroup-item-width-even);
  }
}

@media (min-width: 33.0625rem) {
  .TeaserGroup {
    margin: 0 calc(-1 * var(--spacing-8));
  }

  .TeaserGroup-item {
    padding: 0 var(--spacing-8) var(--spacing-16);
  }
}
