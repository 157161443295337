/* @define NavigationArrows */

:root {
  --NavigationArrows-borderWidth: 1px;
  --NavigationArrows-animationDuration: 1s;
  --NavigationArrows-transition-duration: 0.75s;
  --NavigationArrows-height: var(--spacing-60);
}

.NavigationArrows-link {
  align-items: center;
  background-color: var(--color-White);
  box-sizing: border-box;
  color: var(--color-typo-Gold-500);
  display: flex;
  font: var(--typo-link-16);
  line-height: var(--typo-link-16-line-height-alt);
  padding: 0 var(--spacing-24);
  text-decoration: none;
  text-transform: uppercase;
}

.NavigationArrows-prev .NavigationArrows-icon {
  margin-left: var(--spacing-4);

  /* Centers left icon */
  transform: translateX(-50%);
}

.NavigationArrows-next .NavigationArrows-icon {
  margin-right: var(--spacing-4);

  /* Centers right icon */
  transform: translateX(50%);
}

.NavigationArrows-disabled--prev,
.NavigationArrows-disabled--next {
  cursor: default;
  pointer-events: none;
}

@media (max-width: 48rem) {
  .NavigationArrows {
    display: flex;
    justify-content: center;
    position: relative;
  }

  .NavigationArrows-link {
    flex: 1;
    height: var(--NavigationArrows-height);
    justify-content: center;
  }

  .NavigationArrows-prev {
    border-right: var(--NavigationArrows-borderWidth) solid
      var(--color-Gray-200);
  }
}

@media (min-width: 48.0625rem) and (max-width: 64rem) {
  .NavigationArrows-link {
    height: var(--spacing-45);
    max-width: var(--spacing-45);
  }

  .NavigationArrows-prev .NavigationArrows-icon {
    margin-left: 0;
  }

  .NavigationArrows-next .NavigationArrows-icon {
    margin-right: 0;
  }
}

@media (min-width: 48.0625rem) {
  .NavigationArrows-link {
    box-shadow: var(--box-shadow-default);
    overflow: hidden;
    position: fixed;

    /* Vertically centers link on the screen */
    top: 50%;

    /* Vertically centers link on the screen */
    transform: translateY(-50%);
    transition: max-width var(--NavigationArrows-transition-duration) ease;
    white-space: nowrap;
  }

  .NavigationArrows-prev {
    animation: var(--transition-duration-750) slideInFromLeft ease forwards;
    left: 0;
  }

  /* stylelint-disable-next-line */
  .is-hidden-navigation .NavigationArrows-prev {
    animation: var(--transition-duration-750) slideOutFromLeft ease forwards;
  }

  .NavigationArrows-next {
    animation: var(--transition-duration-750) slideInFromRight ease forwards;
    justify-content: flex-end;
    right: 0;
  }

  /* stylelint-disable-next-line */
  .is-hidden-navigation .NavigationArrows-next {
    animation: var(--transition-duration-750) slideOutFromRight ease forwards;
  }

  .NavigationArrows-label {
    opacity: 0;
    transition: opacity var(--NavigationArrows-transition-duration) ease;
  }

  .NavigationArrows-icon {
    font-size: var(--typo-link-25-font-size);
  }

  .NavigationArrows-link:hover {
    /* Makes container fit the content */
    max-width: 50vw;
  }

  .NavigationArrows-link:hover .NavigationArrows-label {
    opacity: 1;
  }
}

@media (min-width: 64.0625rem) {
  .NavigationArrows-link {
    height: var(--NavigationArrows-height);
    max-width: var(--spacing-60);
  }
}

@media (min-width: 90.0625rem) {
  .NavigationArrows-link {
    font: var(--typo-link-18);
  }
}

/* -- Starts fix for right arrow on IE11 -- */

@media (min-width: 48.0625rem) {
  .NavigationArrows-next .NavigationArrows-icon {
    position: absolute;
    right: var(--spacing-12);

    /* Centers the absolute positioned element vertically + transform */
    top: 50%;

    /* Centers the absolute positioned element vertically + top */
    transform: translateY(-50%);
  }

  .NavigationArrows-next .NavigationArrows-label {
    margin-right: calc(var(--spacing-12) + var(--spacing-16));
  }
}

@media (min-width: 48.0625rem) and (max-width: 64rem) {
  .NavigationArrows-next {
    min-width: var(--spacing-45);
  }
}

@media (min-width: 64.0625rem) {
  .NavigationArrows-next {
    min-width: var(--spacing-60);
  }
}

/* -- Ends fix for right arrow on IE11 -- */
