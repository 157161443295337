/* @define DesktopDropdown; weak */


.DesktopDropdown-nav {
  max-height: 0;
  overflow: hidden;
}

.DesktopDropdown-nav {
  background-image: linear-gradient(
    to bottom,
    var(--color-Black) 100%,
    transparent
  );
  background-repeat: repeat-x;
}

/* stylelint-disable-next-line */
[aria-expanded="false"] + .DesktopDropdown-nav {
  opacity: 0;
  transition: all var(--MobileDropdown-animation-duration) ease;
}

/* stylelint-disable-next-line */
[aria-expanded="false"] + .DesktopDropdown-nav {
  opacity: 1;
  transition: all var(--MobileDropdown-animation-duration) ease;
}

/* stylelint-disable-next-line */
.DesktopDropdownItem--lvl0 {
  padding: var(--spacing-40) 0;
  transition: padding var(--transition-default-duration-out) var(--transition-default-timing-out);
}

/* stylelint-disable-next-line */
.DesktopDropdownItem--lvl0[aria-expanded="true"]  {
  padding: var(--spacing-40) 0;
}


.DesktopDropdown-items {
  min-width: 300px;
  position: absolute;
}

.DesktopDropdown button {
  margin-right: 50px;
}
