html {
  box-sizing: border-box;

  /* Makes rem sizes base 10 */
  font-size: 62.5%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  scroll-behavior: smooth;
}

body {
  background-color: var(--color-White);
  box-sizing: border-box;
  color: var(--color-typo-White);
  font: var(--typo-copy-14);
  height: var(--fullHeight);
  overflow: hidden;
}

a {
  color: inherit;
}

em {
  font-style: italic;
}

strong {
  font-weight: bold;
}

@media (min-width: 48.0625rem) and (max-width: 64rem) {
  body {
    font: var(--typo-copy-15);
  }
}

@media (min-width: 64.0625rem) and (max-width: 90rem) {
  body {
    font: var(--typo-copy-16);
  }
}

@media (min-width: 90.0625rem) {
  body {
    font: var(--typo-copy-20);
  }
}

/* stylelint-disable */
.u-visuallyHidden {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
/* stylelint-enable */

.u-center {
  margin-left: auto;
  margin-right: auto;
}

.u-left {
  text-align: left !important;
}
