/* @define Event */

:root {
  --Event-border: 2px solid var(--color-Gold-200);
}

.Event {
  background-color: var(--color-background-overlay);
  border: var(--Event-border);
  padding: var(--spacing-16) var(--spacing-20) var(--spacing-20);
}

.Event-title {
  font: var(--typo-headline-18);
  margin-bottom: 10px;
  margin-top: 5px;

}

.Event-detailsWrapper {
  margin-top: var(--spacing-24);
}

.Event-detail:last-child {
  position: relative;
}

.Event-detailTitle {
  color: var(--color-typo-Gold-200);
  font-weight: bold;
}

.Event-subline {
  text-decoration: underline;
}

.Event-detailIcon {
  color: var(--color-typo-Gold-200);
  display: block;
  font-size: var(--typo-headline-18-font-size);
  position: absolute;
  right: 0;
}


.Event-detailIconSocial {
  display: flex;
  justify-content: space-evenly;
  width: 100px;
}

.Event-detailInfo {
  font-size: var(--typo-copy-14);
  max-width: 70%;
}

/* stylelint-disable-next-line */
.Event-body.RichText {
  margin-top: var(--spacing-30);
}

@media (max-width: 48rem) {
  .Event-detailTitle,
  .Event-detailParagraph {
    font: var(--typo-copy-14);
  }

  .Event-detailIcon {
    bottom: 0;
    margin-bottom: var(--spacing-10);
  }

  .Event-detail:not(:first-child) {
    margin-top: var(--spacing-10);
  }
}

@media (min-width: 48.0625rem) {
  .Event-detailTitle,
  .Event-detailParagraph {
    font: var(--typo-copy-16);
  }

  .Event-detailInfo {
    font-size: var(--typo-copy-14-font-size);
  }

  .Event-detailsWrapper {
    display: flex;
  }

  .Event-detail {
    flex: 3;
  }

  .Event-detail:first-child {
    flex: 4;
  }

  .Event-detail:not(:last-child) {
    margin-right: var(--spacing-16);
  }

  .Event-detailIcon {
    top: 0;
  }
}
