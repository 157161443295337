/* @define Modal; weak */

:root {
  --Modal-overlayBackground: rgba(var(--color-Black-rgb), 0.75);
  --Modal-closeButtonBackground: rgba(var(--color-White-rgb), 0.75);
  --Modal-minScaleSize: scale(0.9);
  --Modal-closeIconButtonSize: 2em;
  --Modal-closeIconSize: 1.3rem;
  --Modal-desktopSizeSmall: 65vw;
  --Modal-mobileSize: 100vw;
  --Modal-desktopSizeLarge: 85vw;
  --Modal-maxDesktopSize: 100rem;
}

.Modal-overlay {
  background: var(--Modal-overlayBackground);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 11;
}

.Modal-overlay,
.Modal-close {
  align-items: center;
  display: flex;
  justify-content: center;
}

.Modal-container {
  position: relative;
}

.Modal-header {
  position: absolute;
  right: var(--spacing-20);
  top: var(--spacing-20);
  z-index: 12;
}

.Modal-close {
  background-color: var(--color-White);

  /* Makes a rounded button */
  border-radius: 50%;
  color: var(--color-typo-Gold-200);
  cursor: pointer;
  font-size: var(--Modal-closeIconSize);
  height: var(--Modal-closeIconButtonSize);
  outline: none;
  width: var(--Modal-closeIconButtonSize);
}

.Modal-close:hover,
.Modal-close:focus {
  background-color: var(--color-Gold-200);
  color: var(--Modal-closeButtonBackground);
}

.Modal-video {
  /* Spans entire height of parent */
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;

  /* Spans entire width of parent */
  width: 100%;
}

.Modal-video .vjs-big-play-button {
  display: none;
}

@media (max-width: 48rem) {
  .Modal-container {
    width: var(--Modal-mobileSize);
  }
}

@media (min-width: 48.0625rem) {
  .Modal-container {
    width: var(--Modal-desktopSizeSmall);
  }

  .Modal-container--large {
    max-width: var(--Modal-maxDesktopSize);
    width: var(--Modal-desktopSizeLarge);
  }
}

@keyframes Modal-fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes Modal-fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes Modal-zoomIn {
  from {
    transform: var(--Modal-minScaleSize);
  }

  to {
    transform: scale(1);
  }
}

@keyframes Modal-zoomOut {
  from {
    transform: scale(1);
  }

  to {
    transform: var(--Modal-minScaleSize);
  }
}

.Modal {
  display: none;
}

.Modal.is-open {
  display: block;
}

.Modal[aria-hidden="false"] .Modal-overlay {
  animation: Modal-fadeIn var(--transition-duration-750) ease forwards;
}

.Modal[aria-hidden="false"] .Modal-container {
  animation: Modal-zoomIn var(--transition-duration-750) ease forwards;
}

.Modal[aria-hidden="true"] .Modal-overlay {
  animation: Modal-fadeOut var(--transition-duration-750) ease forwards;
}

.Modal[aria-hidden="true"] .Modal-container {
  animation: Modal-zoomOut var(--transition-duration-750) ease forwards;
}
