/* @define TeaserVideo */

:root {
  --TeaserVideo-playButtonSize: 2.4em;
  --TeaserVideo-iconSize: 2.4rem;
}

.TeaserVideo {
  padding-bottom: var(--spacing-20);
}

.TeaserVideo-imageWrapper {
  border: var(--Teaser-borderWidth) solid var(--color-Gold-200);
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  outline: none;
  overflow: hidden;

  /* Keeps aspect ratio */
  padding-bottom: 59.8%;
  position: relative;

  /* Spans entire width of parent container */
  width: 100%;
}

.TeaserVideo-buttonInner,
.TeaserVideo-playButton {
  align-items: center;
  display: flex;
  justify-content: center;
}

.TeaserVideo-buttonInner {
  background: var(--dark-overlay-background);
  background-size: var(--dark-overlay-backgroundSize-initial);
  box-sizing: border-box;

  /* Spans entire height of parent container */
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transition: background-size var(--transition-duration-300)
    var(--transition-default-timing-in);

  /* Spans entire width of parent container */
  width: 100%;
}

.TeaserVideo-imageWrapper:hover .TeaserVideo-buttonInner,
.TeaserVideo-imageWrapper:focus .TeaserVideo-buttonInner {
  background-size: var(--dark-overlay-backgroundSize-final);
}

.TeaserVideo-playButton {
  background-color: var(--color-White);

  /* Makes the border round */
  border-radius: 50%;
  color: var(--color-typo-Gold-200);
  font-size: var(--TeaserVideo-iconSize);
  height: var(--TeaserVideo-playButtonSize);
  opacity: 0;
  transition: opacity var(--transition-duration-300)
    var(--transition-default-timing-out);
  width: var(--TeaserVideo-playButtonSize);
}

.TeaserVideo-imageWrapper:hover .TeaserVideo-playButton,
.TeaserVideo-imageWrapper:focus .TeaserVideo-playButton {
  opacity: 1;
}

.TeaserVideo-iconWrapper {
  display: block;

  /* Centers the play button in the circle */
  margin-left: var(--spacing-4);
}

.TeaserVideo-title {
  font: var(--typo-headline-25);
  margin-top: var(--spacing-20);
}

.TeaserVideo-description {
  font: var(--typo-copy-14);
  margin-top: var(--spacing-16);
  padding-right: var(--spacing-20);
}

.TeaserVideo-image {
  /* Spans entire height of parent container */
  height: 100%;
  left: 0;
  object-fit: cover;
  object-position: center;
  position: absolute;
  top: 0;

  /* Spans entire width of parent container */
  width: 100%;
}

.TeaserVideo-title--insideContainer {
  bottom: var(--spacing-16);
  color: var(--color-typo-White);
  left: var(--spacing-16);
  position: absolute;
}

@media (max-width: 48rem) {
  .TeaserVideo-button {
    margin-top: var(--spacing-30);
  }
}

@media (min-width: 48.0625rem) {
  .TeaserVideo-button {
    margin-top: var(--spacing-20);
  }
}
