/* @define News */

:root {
  --News-border: 2px solid var(--color-Gold-200);
}

.News {
  background-color: var(--color-background-overlay);
  border: var(--News-border);
  padding: var(--spacing-16) var(--spacing-20) var(--spacing-20);
}

.News-body {
  margin-top: var(--spacing-30);
}
