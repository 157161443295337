/* @define FooterNavigation */

:root {
  --FooterNavigation-slideIn-delay: 0.5s;
}

@keyframes slideInFromBottom {
  from {
    margin: 0 0 calc(-1 * var(--FooterNavigation-height));
  }

  to {
    margin: 0;
  }
}

/*
 * Animating margin here instead of transform as this makes sure
 * that the whole content is animated, not only the animated element iself,
 * otherwise we would see some not so nice effects where the content
 * all of a sudden jumps.
 */

@keyframes slideOutToBottom {
  from {
    margin: 0;
  }

  to {
    margin: 0 0 calc(-1 * var(--FooterNavigation-height));
  }
}

:root {
  --FooterNavigation-height: 10rem;
  --FooterNavigation-borderWidth: 1px;
}

@media (max-width: 48rem) {
  .FooterNavigation {
    display: none;
  }
}

@media (min-width: 48.0625rem) {
  .FooterNavigation {
    border-top: var(--FooterNavigation-borderWidth) solid
      var(--color-page-divider);
    display: flex;
    height: var(--FooterNavigation-height);
    justify-content: space-between;
  }

  .FooterNavigation-subnav--prev {
    border-right: var(--FooterNavigation-borderWidth) solid
      var(--color-page-divider);
    padding-right: var(--spacing-10);
  }

  .FooterNavigation-subnav--next {
    border-left: var(--FooterNavigation-borderWidth) solid
      var(--color-page-divider);
    padding-left: var(--spacing-10);
  }

  /* stylelint-disable-next-line */
  .is-animated .FooterNavigation {
    animation: var(--transition-duration-1000) slideInFromBottom
      var(--FooterNavigation-slideIn-delay) ease forwards;
    margin: 0 0 calc(-1 * var(--FooterNavigation-height));
  }

  /* stylelint-disable-next-line */
  .is-hidden-navigation .FooterNavigation,
  .FooterNavigation.is-slidedOut {
    animation: var(--transition-duration-1000) slideOutToBottom ease forwards;
  }

  .FooterNavigation-list {
    display: flex;

    /* Spans entire height of parent element */
    height: 100%;
    justify-content: center;
    margin: 0 auto;
  }
}
